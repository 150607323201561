import AppLayout from 'layouts/AppLayout'

import './OpenAccount.scss'

const OpenAccount = () => {
  return (
    <AppLayout useContentHeader={false} contentClassName="open-account">
      <h1 className="open-account__title">Estamos quase lá...</h1>
      <p className="open-account__description">
        <strong>baixe o nosso APP</strong> e termine de abrir sua conta
        totalmente grátis!
      </p>

      <img
        src="/images/rvbr-app.png"
        alt="Logo do aplicativo RVBR com fundo vermelho e escrito RVBR em branco centralizado"
        className="open-account__app-logo"
      />

      <img
        src="/images/app-store.png"
        alt="Baixar na App Store"
        className="open-account__app-store --with-margin"
      />

      <img
        src="/images/google-play.png"
        alt="Baixar na Google Play"
        className="open-account__app-store"
      />
    </AppLayout>
  )
}

export default OpenAccount
