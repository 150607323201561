import ContentHeader from 'components/ContentHeader'
import Header from 'components/Header'
import { If } from '@redsales/ds'

import './AppLayout.scss'
import * as classNames from 'classnames'

const AppLayout = ({
  children,
  useHeader = true,
  useContentHeader = true,
  contentHeaderInfos,
  contentClassName,
  layoutClassName,
}) => {
  return (
    <main className={classNames('app-layout', layoutClassName)}>
      <If condition={useHeader} renderIf={<Header />} />
      <If
        condition={useContentHeader}
        renderIf={<ContentHeader {...contentHeaderInfos} />}
      />

      <section className={classNames('app-layout__content', contentClassName)}>
        {children}
      </section>
    </main>
  )
}

export default AppLayout
